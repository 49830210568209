import { FC } from "react";

import * as S from "./styles";

interface TableMobileCellProps {
  firstLineData: string;
  secondLineData: string;
}

export const TableMobileCell: FC<TableMobileCellProps> = ({ firstLineData, secondLineData }) => {
  return (
    <S.Container>
      <S.InfoText>{firstLineData}</S.InfoText>
      <S.InfoText>{secondLineData}</S.InfoText>
    </S.Container>
  );
};
