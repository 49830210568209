interface Layout {
  SITE_PADDING: string;
  SITE_PADDING_HORIZONTAL: string;
  HEADER_HEIGHT: string;
  FOOTER_HEIGHT: string;
  BORDER_RADIUS: string;
}

const SITE_PADDING = "10px";
const HEADER_HEIGHT = "60px";
const FOOTER_HEIGHT = "20px";
const BORDER_RADIUS = "2px";

export const LAYOUT: Layout = {
  SITE_PADDING,
  SITE_PADDING_HORIZONTAL: `padding-left: ${SITE_PADDING}; padding-right: ${SITE_PADDING};`,
  HEADER_HEIGHT,
  FOOTER_HEIGHT,
  BORDER_RADIUS,
};
