import { useTranslation } from "react-i18next";

export const HelpCentreLink = () => {
  const { t, i18n } = useTranslation();

  return <a href={getHelpCentreLinkByLanguage(i18n.language)}>{t("GoBolt Help Centre")}</a>;
};

function getHelpCentreLinkByLanguage(lang: string) {
  if (lang === "en") {
    return "https://help.gobolt.com/hc/en-us";
  }

  if (lang === "fr") {
    return "https://help.gobolt.com/hc/fr-ca";
  }

  return "https://help.gobolt.com/hc/en-us";
}
