import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import BarLoader from "react-spinners/BarLoader";

import { replace, capitalize, toLower } from "lodash";

import * as S from "./styles";
import { COLORS } from "styles";
import { SCREEN_SIZES } from "styles/screenSizes";

import { DeliveryInstructions, useDeliveryInstructions } from "components/DeliveryInstructions";
import { HelpCentreLink } from "components/HelpCentreLink";
import { LanguageSwitcher } from "components/LanguageSwitcher";
import { PageBanner } from "components/PageBanner";
import { useSearchTrackingInfo } from "pages/TrackingSearchPage/useSearchTrackingInfo";

import { buildDescriptionWithCity, getCurrentFlowSequence } from "./helpers";
import { ShipmentProgress } from "./ShipmentProgress";
import { TrackingInfoCard } from "./TrackingInfoCard";
import { TransitTrackingBar } from "./TransitTrackingBar";
import { UpdateAddressButton } from "./UpdateAddressButton";
import { useValidateTrackingNumber } from "./useValidateTrackingNumber";
import { ValidatePostalCodeField } from "./ValidatePostalCodeField";

export const TrackingInfoPage: FC = () => {
  const { t } = useTranslation();
  const { trackingInfo } = useSearchTrackingInfo();
  const isMobile = useMediaQuery({ maxWidth: SCREEN_SIZES.sm - 1 });
  const [isShipmentProgressDrawerVisible, setIsShipmentProgressVisible] = useState(false);
  const history = useHistory();
  const {
    isOpen: isDeliveryInstructionsDrawerOpen,
    openDrawer: openDeliveryInstructionsDrawer,
    closeDrawer: closeDeliveryInstructionsDrawer,
  } = useDeliveryInstructions();

  useValidateTrackingNumber();

  if (!trackingInfo)
    return (
      <>
        <PageBanner />
        <S.LoaderContainer>
          <BarLoader color={COLORS.BLUE} loading width={isMobile ? "25vw" : "15vw"} height={4} />
        </S.LoaderContainer>
      </>
    );

  const currentFlowSequence = getCurrentFlowSequence(trackingInfo.label_status, trackingInfo.transit_flow);

  const renderTrackAnotherPackageButton = () => {
    return (
      <S.TrackAnotherPackageButton onClick={() => history.push("/tracking")}>
        {t("Track another package")}
      </S.TrackAnotherPackageButton>
    );
  };

  const renderTitle = () => {
    const { desc, city } = buildDescriptionWithCity(trackingInfo.label_description || "");
    return t(replace(toLower(desc), /\w+/g, capitalize)) + ` ${city}`;
  };

  return (
    <>
      <PageBanner pageInfo={trackingInfo?.tracking_number} />
      <S.PageContainer>
        <S.TitleContainer>
          <S.Title>
            {isMobile
              ? `${t("Tracking #")}-${trackingInfo.tracking_number}`
              : renderTitle()}
          </S.Title>
          {!isMobile && renderTrackAnotherPackageButton()}
        </S.TitleContainer>
        <TrackingInfoCard buttonText={t("View Shipment Progress")} onClick={() => setIsShipmentProgressVisible(true)}>
          <TransitTrackingBar
            currentLabelStatus={trackingInfo.label_status}
            transitFlow={trackingInfo.transit_flow}
            deliveredAt={trackingInfo.label_delivered_at}
          />
        </TrackingInfoCard>

        <ShipmentProgress
          progressEvents={trackingInfo.transit_steps}
          visible={isShipmentProgressDrawerVisible}
          onClose={() => setIsShipmentProgressVisible(false)}
        />

        <S.Row isMobile={isMobile}>
          <ValidatePostalCodeField currentFlowSequence={currentFlowSequence} />

          <S.ActionButtons>
            <UpdateAddressButton trackingInfo={trackingInfo} />
            <DeliveryInstructions.Button onClick={openDeliveryInstructionsDrawer} />
          </S.ActionButtons>
        </S.Row>

        {isMobile && renderTrackAnotherPackageButton()}

        <S.FooterContainer>
          <S.FooterTitle>{t("Need help?")}</S.FooterTitle>
          <S.FooterText>
            {t("Visit the")} <HelpCentreLink />
          </S.FooterText>

          <LanguageSwitcher />
        </S.FooterContainer>
      </S.PageContainer>

      <DeliveryInstructions.Drawer
        open={isDeliveryInstructionsDrawerOpen}
        trackingNumber={trackingInfo.tracking_number}
        shouldShowWarning={
          trackingInfo.label_status === "label_out_for_delivery" || trackingInfo.label_status === "label_delivered"
        }
        onClose={closeDeliveryInstructionsDrawer}
      />
    </>
  );
};
